@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  background-image: linear-gradient(45deg, #c6d6fd 0%, #c1d5f8 99%, #cfddf8 100%);
}

.main, .things {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #4381eb;
  border-bottom: .25px solid rgb(67, 129, 235);
}

.things {
  min-height: 100vh !important;
  flex-direction: row;
  justify-content: space-around;
}

.nav {
  min-height: 10vh;
}

h1 {
  font-weight: lighter;
}

@media (prefers-reduced-motion: no-preference) {

}
